import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Home from './Containers/Home';
import ActionPlanList from './Containers/ActionPlanList';
import ActionPlanDashboard from './Containers/ActionPlanDashboard';
import ActionPlanBulkEdit from './Containers/ActionPlanBulkEdit';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Home
    },
    {
      path: '/action-plan-list',
      exact: true,
      component: ActionPlanList,
    },
    {
      path: '/action-plan-dashboard',
      exact: true,
      component: ActionPlanDashboard,
    },
    {
      path: '/action-plan-bulk-edit',
      exact: true,
      component: ActionPlanBulkEdit,
    },
    {
      path: '/id-homepage',
      exact: true,
      component: IDHomepage,
    },
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};
