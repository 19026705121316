export const ROLE_FORWOOD_ID_ADMIN = 'ForwoodIdAdmin';
export const ROLE_MANAGER = 'Manager';
export const ROLE_SITE_ADMIN = 'SiteAdmin';
export const ROLE_TEAMS_ACCESS = 'TeamsAccess';
export const ROLE_MAP_REPORT_ACCESS = 'MapReportAccess';
export const ROLE_GEO_EDITOR_ACCESS = 'GeoeditorAccess';
export const ROLE_VERIFICATIONS_ACCESS = 'VerificationsAccess';
export const ROLE_TEAM_TARGETS_ACCESS = 'TeamTargetsAccess';
export const ROLE_ACTIONS_ACCESS = 'ActionsAccess';
export const ROLE_QUICK_SIGHT_ACCESS = 'QuickSightAccess';
