import { connect } from 'react-redux';
import Home from '../../Components/Home';
import { mapToIframeLanguage } from '../../translations/i18n';
import withIframeHistory from '../withIframeHistory';
import { HOME } from '../../constants/screens';
import config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[HOME],
  ...ownProps,
});

export default connect(
  mapStateToProps,
  {}
)(
  withIframeHistory(Home, {
    name: HOME,
    defaultUrl: config.reactApp.CRM_HOMEPAGE_URL,
  })
);
