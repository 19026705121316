import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe, getParsedIrameUrl } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';
import { HOME } from '../../constants/screens';

export default function Home({
  currentUrl, currentLanguage, onUnMount, onMount
}) {
  const parsedIframeUrl = useMemo(
    () => getParsedIrameUrl(currentUrl, currentLanguage),
    [currentLanguage]
  );

  useEffect(() => {
    onMount(getParsedIrameUrl(Config.reactApp.CRM_HOMEPAGE_URL, currentLanguage));

    return () => {
      onUnMount();
    };
  }, []);

  return (
    <Can I="view" a={HOME}>
      <LoadingIframe src={parsedIframeUrl} />
    </Can>
  );
}

Home.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  onUnMount: PropTypes.func,
};

Home.defaultProps = {
  onUnMount: () => {},
  onMount: () => {},
};
