import { connect } from 'react-redux';
import ActionPlanDashboard from '../../Components/ActionPlanDashboard';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { ACTION_PLAN_DASHBOARD } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[ACTION_PLAN_DASHBOARD],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(ActionPlanDashboard, {
  name: ACTION_PLAN_DASHBOARD,
  defaultUrl: Config.reactApp.ACTION_PLAN_DASHBOARD_URL
}));
