import { Ability, AbilityBuilder } from '@casl/ability';
import { MANAGER, SUPERVISOR } from '../../constants/jobRoleCategory';
import {
  ROLE_ACTIONS_ACCESS,
  ROLE_FORWOOD_ID_ADMIN,
  ROLE_SITE_ADMIN,
} from '../../constants/roles';
import * as screens from '../../constants/screens';

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility, decodedIdToken) {
  const { can, rules } = new AbilityBuilder();

  if (
    !decodedIdToken
    || !decodedIdToken['cognito:groups']
    || !decodedIdToken['cognito:groups'].length
  ) return;

  const cognitoGroups = decodedIdToken['cognito:groups'];
  const jobRoleCategory = decodedIdToken.job_role_category;

  if (![ROLE_ACTIONS_ACCESS].every((role) => cognitoGroups.includes(role))) return;

  can('view', screens.HOME);
  let actionPlanHasItem = false;

  if ([ROLE_FORWOOD_ID_ADMIN].every((role) => cognitoGroups.includes(role))) {
    can('view', screens.ACTION_PLAN_BULK_EDIT);
    actionPlanHasItem = true;
  }

  if (
    [ROLE_SITE_ADMIN].every((role) => cognitoGroups.includes(role))
    || jobRoleCategory === MANAGER
  ) {
    can('view', screens.ACTION_PLAN_LIST);
    can('view', screens.ACTION_PLAN_DASHBOARD);
    actionPlanHasItem = true;
  }

  if (jobRoleCategory === SUPERVISOR) {
    can('view', screens.ACTION_PLAN_LIST);
    actionPlanHasItem = true;
  }

  if (actionPlanHasItem) can('view', 'ManagerMenu');

  currentAbility.update(rules);
}

export default ability;
