import Config from '../Config';
import * as screens from '../constants/screens';

const IFRAME_HISTORY_CHANGE_URL = 'IFRAME_HISTORY_CHANGE_URL';
const IFRAME_HISTORY_CURRENT_SCREEN = 'IFRAME_HISTORY_CURRENT_SCREEN';

export function iframeHistoryDefaultState() {
  return {
    currentScreen: null,
    [screens.HOME]: Config.reactApp.CRM_HOMEPAGE_URL,
    // Manager Menu
    [screens.ACTION_PLAN_LIST]: Config.reactApp.ACTION_PLAN_LIST_URL,
    [screens.ACTION_PLAN_DASHBOARD]: Config.reactApp.ACTION_PLAN_DASHBOARD_URL,
    [screens.ACTION_PLAN_BULK_EDIT]: Config.reactApp.ACTION_PLAN_BULK_EDIT_URL,
  };
}

export function setCurrentScreen(dispatch, payload) {
  dispatch({
    type: IFRAME_HISTORY_CURRENT_SCREEN,
    payload,
  });
}

export function setIframeHistoryUrl(dispatch, { name, url }) {
  dispatch({
    type: IFRAME_HISTORY_CHANGE_URL,
    payload: { name, url },
  });
}

export default (state = iframeHistoryDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case IFRAME_HISTORY_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload,
      };

    case IFRAME_HISTORY_CHANGE_URL:
      return {
        ...state,
        [payload.name]: payload.url,
      };

    default:
      return state;
  }
};
