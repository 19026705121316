import * as screens from '../../constants/screens';
/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
const buildMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.ACTION_PLAN_LIST)) {
    menuItems.push({
      container: 'Action Plan List',
      icon: undefined,
      title: t('sideMenu:actionPlanList'),
      path: '/action-plan-list',
    });
  }

  if (ability.can('view', screens.ACTION_PLAN_DASHBOARD)) {
    menuItems.push({
      container: 'Action Plan Dashboard',
      icon: undefined,
      title: t('sideMenu:actionPlanDashboard'),
      path: '/action-plan-dashboard',
    });
  }

  if (ability.can('view', screens.ACTION_PLAN_BULK_EDIT)) {
    menuItems.push({
      container: 'Action Plan Bulk Edit',
      icon: undefined,
      title: t('sideMenu:actionPlanBulkEdit'),
      path: '/action-plan-bulk-edit',
    });
  }

  return menuItems;
};

export default buildMenuItems;
